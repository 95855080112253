import React, { CSSProperties, useState } from "react";
import Logo from "../dss.png";

interface Props {
  loading: boolean;
}

const RotatingImage: React.FC<Props> = ({ loading }) => {
  const [isRotating, setIsRotating] = useState(false);

  const handleClick = () => {
    setIsRotating(true);
    setTimeout(() => setIsRotating(false), 2000); // reset rotation after 2 seconds
  };

  const rotateStyle: CSSProperties = {
    pointerEvents: "none",
    animation: "rotate 2s ease-in-out",
    animationIterationCount: 1,
    animationFillMode: "forwards", // ensure the animation retains its end state
  };

  const keyframes = `
    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(1080deg); /* 2 full rotations (360 * 2) */
        }
    }
`;

  return (
    <div style={{ width: "74%" }}>
      <style>{keyframes}</style>
      <img
        src={Logo}
        alt="Rotating"
        onClick={handleClick}
        style={isRotating || loading ? rotateStyle : {}}
      />
    </div>
  );
};

export default RotatingImage;
